import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"

import {MainList,CatList} from '../components/utils.js'

const Projects = ({ data }) => {
	const totalCount = data.projects.totalCount;
	const totalTalk = data.talks.totalCount;
	return (
	<Layout location={'projects'}>
		<div className={"container"}>
			<MainList project={true} total={totalCount} totalTalk={totalTalk}/>
			<CatList/>
			{data.projects.edges.map(({ node }) => (
			<section key={node.id}>
				<Link to={node.fields.slug}>
					<h3>
						{node.frontmatter.title}
					</h3>
					<p>{node.frontmatter.date}</p>
					<div className={"row"}>
						<div className={"col col40"}>
							<GatsbyImage className="rnd" image={node.frontmatter.featuredImage.childImageSharp.gatsbyImageData} alt="image of project"/>
						</div>
						<div className={"col col60"}>
							<div className={"row"}>
								<div className={"lft pd"}>
									<p>{node.excerpt}</p>
								</div> 
							</div>
						</div>
					</div>
				</Link>
			</section>
			))}
			<MainList project={true} total={totalCount} totalTalk={totalTalk}/>
		</div>
	</Layout>
  )
}

export default Projects;

export const query = graphql`
  query {
    projects: allMarkdownRemark(
    	sort: { fields: [frontmatter___date], order: DESC }
    	filter: {frontmatter: {template: {eq: "project"}}}
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
               }
            }
          }
          fields {
            slug
          }
          excerpt(pruneLength: 300)
        }
      }
    }
    talks: allMarkdownRemark(
    	filter: {frontmatter: {template: {eq: "talk"}}}
    ) {
      totalCount
    }
  }
`